export default {

    api: {
        baseurl: "https://api.rev.eatery-portal.com/",
        version: "api/v0.1"
    },

    favicon: {
        prod: "/favicon.ico",
        stg: "/favicon_stg.ico",
        rev: "/favicon_rev.ico",
        dev: "/favicon_dev.ico"
    },

    css: {
        prod: {
            sp: [
                "/css/sp/sp_style.css",
            ],
            pc: [
                "/css/pc/style.css",
                "/css/pc/timemanagement.css",
            ],
        },
        dev: {
            sp: [
                "/css/sp/sp_style.css",
            ],
            pc: [
                "/css/pc/style.css",
                "/css/pc/timemanagement.css",
            ],
        }
    },

    // アプリ用システム制御判定
    system_mode: {
        smartphone_app: "app",
        website: "web",
    },

    // Datadogログレベル
    datadog_level: {
        error: "error",
        warn: "warn",
        debug: "debug",
    },

    // モバイル基準値
    mobile_breakpoint: 760,

    // 暗号化キー
    crypto_key: "eatery-crypto!",

    // チャット再読み込み間隔
    chat_reload_msec: 30000,    //TODO:開発中は頻繁にアクセスしても邪魔なので間隔長めに

    // チャット新着メッセージ確認
    chat_message_reload_sec: 300,

    // トークンリフレッシュ間隔
    token_refresh_term_sec: 2 * 60 * 60,

    // 論理削除フラグ
    delete_flg: {
        false: 0,   // 削除しない
        true: 1,    // 削除する
    },

    // APIステータス
    api_status: {
        valid: 0,   // 有効
        invalid: 1, // 無効
    },

    // 画像圧縮
    image_compression: {
        maxSizeMB: 5,
        // maxWidthOrHeight: 640
    },

    // 添付ファイル
    attached_files: {
        limit_size: 10485760    // 10MB
    },

    // お知らせ
    notification: {
        download_limit: 3,
    },

    // ソースバージョン
    source_version: 20241002113848,
    
    // 消費税率
    tax: 1.1,
}